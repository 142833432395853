import React from "react";

const ButtonImage = ({ src, text, handleClick }) => {
  return (
    <button class="buttonImage" onClick={handleClick}>
      <img src={src} alt={text}  onClick={handleClick}/>
    </button>
  );
};

export default ButtonImage;
