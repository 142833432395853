import React, { useEffect } from "react";
import { useState } from "react";

const BotResponse = ({ response, chatLogRef, answersBottomRef, firstMessage }) => {
  const [botResoponse, setBotResponse] = useState("");
  const [isPrinting, setIsPrinting] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    let index = 1;
    let msg = setInterval(() => {
      if (firstMessage) {
        setIsButtonVisible(true);
      }
      if (!isPrinting) {
        // if isPrinting is false, clear interval and return
        clearInterval(msg);
        return;
      }
      setBotResponse(response.slice(0, index));
      if (index >= response.length) {
        clearInterval(msg);
        setIsButtonVisible(false);
      }
      index++;

      answersBottomRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 0);
    return () => clearInterval(msg); // clear interval on component unmount
  }, [answersBottomRef, response, isPrinting]);

  const stopPrinting = () => setIsPrinting(!isPrinting);

  return (
    <>
      {/* <pre> */}
      {botResoponse.split('\n').map((text, index) => (
      <React.Fragment key={index}>
        {text}
        {index !== botResoponse.length - 1 ? <br /> : null}
      </React.Fragment>
    ))}
      {/* {botResoponse === response ? "" : "|"} */}
      {/* </pre> */}
      {isButtonVisible && (
        <button className="stop-messgage" onClick={stopPrinting}>
          {isPrinting ? "Stop Message" : "Regenerate Message"}
        </button>
      )}
    </>
  );
};

export default BotResponse;
