import React from "react";
import NavLinks from "./NavLink";
import BuyMeACoffeeButton from "./BuyMeACoffeeButton";

const NavLinksContainer = ({ chatLog, setChatLog }) => {
  return (
    <div className="navLinks" style={{ position: "absolute", bottom: "10px" }}>
    </div>
  );
};

export default NavLinksContainer;
