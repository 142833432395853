import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import ButtonImage from "../components/ButtonImage";
import SvgComponent from "../components/SvgComponent";
import { useNavigate, useParams } from "react-router-dom";

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {}
      )
    : {}
};

const Home = () => {
  const [isSignupFormVisible, setIsSignupFormVisible] = useState(false);
  const navigate = useNavigate();

  const handleClick = async (purpose) => {
    if (purpose === "signup") {
      setIsSignupFormVisible(true);
    }
    if (purpose === "login") {
      navigate("/login");
    }
  };

  return (
    <>
      {!isSignupFormVisible ? (
        <div className="loginContainer">
          <div className="loginContainerContent">
            <SvgComponent />
            <h1>Welcome to Textbooks.Live!</h1>
            <p className="loginDescription">Your textbook, now with live support.</p>
            <p>Select an item to get started:</p>
            <ButtonImage src="/sinfonia-logo.svg" text="Sinfonia" handleClick={() => navigate("/sinfonia")} />
            <div className="loginButtonWrapper">

              
              <ButtonImage src="/risc.svg" text="RISC-V" handleClick={() => navigate("/code")} />
              <ButtonImage src="/powerpc.png" text="PowerPC 603e Processor" handleClick={() => navigate("/mpc")} />
              <ButtonImage src="/swd.png" text="Samsung Washer Dryer Manual" handleClick={() => navigate("/swd")} />
              <ButtonImage src="/mkt.png" text="Principles of Marketing" handleClick={() => navigate("/mkt")} />
              <ButtonImage src="/mgm.png" text="Principles of Management" handleClick={() => navigate("/mgm")} />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Home;