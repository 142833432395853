import "./normal.css";
import "./App.css";
import ChatHome from "./pages/ChatHome";
import ChatHomeSinfonia from "./pages/ChatHomeSinfonia";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";

function App() {
  // const { currentUser } = useContext(AuthContext);
  // const RequireAuth = ({ children }) => {
  //   return currentUser ? children : <Navigate to="auth/login" />;
  // };

  return (
    <Routes>
      <Route
        index
        exact
        path="/"
        element={
          // <RequireAuth>
          <Home />
          // </RequireAuth> 
        }
      />
      <Route
        index
        exact
        path="/mkt"
        element={
          <ChatHome url="https://dev-api.textbooks.live/mkt" />
        }
        
      />
      <Route
        index
        exact
        path="/mgm"
        element={
          <ChatHome url="https://dev-api.textbooks.live/mgm" />
        }
      />
      <Route
        index
        exact
        path="/mpc"
        element={
          <ChatHome url="https://dev-api.textbooks.live/mpc" />
        }
      />
      <Route
        index
        exact
        path="/swd"
        element={
          <ChatHome url="https://dev-api.textbooks.live/swd" />
        }
      />
      <Route
        index
        exact
        path="/code"
        element={
          <ChatHome url="https://dev-api.textbooks.live/code" />
        }
      />
      <Route
        index
        exact
        path="/Sinfonia"
        element={
          // <ChatHomeSinfonia url='http://localhost:5001/ask-product' />
          <ChatHomeSinfonia url='https://dev-api.textbooks.live/ask-product' />
        }
      />
    </Routes>
  );
}

export default App;
