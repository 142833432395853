import Select from 'react-select';
import React, { useEffect, useRef, useState, createRef } from "react";
function ProjectSelector({onOptionChange}) {
    const [options, setOptions] = useState([]);
    const [date, setDate] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        // Fetch data from the API
        // You can use the fetch() function or a library like axios
        // For example, if you are using axios:
        async function fetchData() {
            const res = await fetch('https://dev-api.textbooks.live/loaded-products', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Bypass-Tunnel-Reminder": "true",
                    Authorization:
                        "Basic " +
                        btoa(
                            localStorage.getItem("user") +
                            ":" +
                            localStorage.getItem("password")
                        ),
                }
            });
            const data = await res.json();
            const formattedOptions = data.map(item => ({
                value: item.id, // The unique identifier for each option
                label: item.name, // The display label for each option
            }));
            setOptions(formattedOptions)
            const resDate = await fetch('https://dev-api.textbooks.live/last-update', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Bypass-Tunnel-Reminder": "true",
                    Authorization:
                        "Basic " +
                        btoa(
                            localStorage.getItem("user") +
                            ":" +
                            localStorage.getItem("password")
                        ),
                }
            });
            const date = await resDate.json();
            console.log(date)
            setDate(date)
        }
        fetchData();

    }, []);

    const handleSelectChange = selectedOption => {
        setSelectedOption(selectedOption);
        onOptionChange(selectedOption)
    };
    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#023950",
          // match with the menu
          borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
          // Overwrittes the different states of border
          borderColor: state.isFocused ? "yellow" : "green",
          // Removes weird border around container
          boxShadow: state.isFocused ? null : null,
        }),
        menu: base => ({
          ...base,
          // override border radius to match the box
          borderRadius: 0,
          // kill the gap
          marginTop: 0,
          background: "#023950",
        }),
        menuList: base => ({
          ...base,
          background: "#023950",
          // kill the white space on first and last option
          padding: 0
        })
      };
    

    return (
        <div>
            <div className="last-update">Last update: {date.updatedAt}</div>
            <Select
                options={options}
                value={selectedOption}
                onChange={handleSelectChange}
                placeholder="Select a project"
                styles={customStyles}
            />
        </div>
    );
}

export default ProjectSelector;