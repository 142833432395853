import React from "react";

const SvgComponent = () => {
  return (
    <svg id="svgLogo" viewBox="0 0 1032 276" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
      <path d="M107.058 153.552C114.858 153.552 121.181 147.229 121.181 139.429C121.181 131.629 114.858 125.306 107.058 125.306C99.2581 125.306 92.9351 131.629 92.9351 139.429C92.9351 147.229 99.2581 153.552 107.058 153.552Z" fill="#76FFD8" />
      <path d="M117.072 1.027C113.777 0.353507 110.422 0.00948231 107.058 0H61.3522V78.573H37.4722C27.509 78.6455 17.9785 82.6543 10.9589 89.7252C3.93928 96.796 0 106.355 0 116.319C0 126.283 3.93928 135.842 10.9589 142.913C17.9785 149.984 27.509 153.992 37.4722 154.065L61.6092 154.322H83.1782V99.115H107.058C109.463 99.0808 111.863 98.9091 114.248 98.601C125.726 96.8447 136.225 91.1184 143.916 82.419C151.607 73.7197 156.003 62.5985 156.339 50.9917C156.675 39.385 152.929 28.028 145.755 18.8983C138.58 9.76858 128.43 3.44442 117.073 1.027H117.072ZM61.6092 99.115V124.022C61.5799 126.396 60.6237 128.665 58.9446 130.344C57.2655 132.023 54.9966 132.98 52.6222 133.009H37.7292C33.2797 132.941 29.0356 131.125 25.9132 127.954C22.7908 124.784 21.0406 120.512 21.0406 116.062C21.0406 111.612 22.7908 107.34 25.9132 104.17C29.0356 100.999 33.2797 99.1834 37.7292 99.115H61.6092ZM111.423 78.06C110.065 78.2733 108.69 78.3594 107.315 78.317H83.4352V29.529C83.4645 27.1546 84.4208 24.8857 86.0999 23.2066C87.7789 21.5276 90.0478 20.5713 92.4222 20.542H107.315C111.111 20.4723 114.882 21.1685 118.402 22.589C121.923 24.0096 125.121 26.1253 127.806 28.8097C130.49 31.4942 132.606 34.6922 134.026 38.2128C135.447 41.7334 136.143 45.5043 136.073 49.3C135.816 63.68 125.289 76.005 111.423 78.059V78.06Z" fill="#FF4E00" />
      <path d="M268.847 79.88C275.959 79.88 279.388 86.738 279.388 92.199C279.388 97.787 275.578 104.518 268.339 104.518H251.956V79.88H268.847ZM233.287 151H251.956V120.774H269.228C289.167 120.774 298.057 106.804 298.057 92.199C298.057 77.848 289.167 63.497 269.228 63.497H233.287V151ZM314.346 151H365.781V134.617H333.015V63.497H314.346V151ZM404.864 114.17L413.754 83.69L423.025 114.17H404.864ZM428.994 130.299L436.741 151H456.299L423.914 63.497H403.721L371.209 151H391.021L398.768 130.299H428.994ZM534.681 63.497H516.012V115.821L486.04 63.497H469.022V151H487.564V98.041L518.044 151H534.681V63.497ZM571.728 107.439C571.728 92.834 582.904 80.896 597.509 80.896C611.987 80.896 623.417 92.834 623.417 107.439C623.417 122.044 611.987 133.855 597.509 133.855C582.904 133.855 571.728 122.044 571.728 107.439ZM553.186 107.439C553.186 132.077 572.744 152.27 597.509 152.27C622.401 152.27 641.959 132.077 641.959 107.439C641.959 82.674 622.401 62.481 597.509 62.481C572.744 62.481 553.186 82.674 553.186 107.439ZM695.458 79.88C702.57 79.88 705.999 86.738 705.999 92.199C705.999 97.787 702.189 104.518 694.95 104.518H679.202V79.88H695.458ZM703.586 120.012C718.572 116.964 725.43 104.518 725.43 92.199C725.43 77.848 716.159 63.497 696.347 63.497H660.533V151H679.202V120.774H683.393L702.951 151H725.303L703.586 120.012ZM767.05 114.17L775.94 83.69L785.211 114.17H767.05ZM791.18 130.299L798.927 151H818.485L786.1 63.497H765.907L733.395 151H753.207L760.954 130.299H791.18ZM878.96 119.885L852.544 63.497H837.177L826.382 151H845.051L851.274 98.549L871.848 140.967H886.199L906.646 98.549L912.742 151H931.411L920.743 63.497H905.122L878.96 119.885ZM972.824 114.17L981.714 83.69L990.985 114.17H972.824ZM996.954 130.299L1004.7 151H1024.26L991.874 63.497H971.681L939.169 151H958.981L966.728 130.299H996.954Z" fill="#EEEEEE" />
      <path d="M675.927 194.844H688.451C699.46 194.844 705.116 204.843 705.116 216.357C705.116 227.77 699.46 237.567 688.451 237.567H675.927V194.844ZM688.451 251C708.348 251 719.862 236.153 719.862 216.357C719.862 196.561 708.348 181.411 688.451 181.411H661.181V251H688.451ZM735.621 251H780.566V237.971H750.367V223.124H776.021V210.297H750.367V194.44H779.657V181.411H735.621V251ZM792.318 230.598C792.318 243.122 802.519 252.111 817.568 252.111C829.991 252.111 842.313 245.445 842.616 231.709C842.919 225.144 840.394 215.549 823.527 210.701L815.245 208.075C808.377 206.156 807.771 202.823 807.771 201.005C807.771 196.864 811.609 194.036 816.76 194.036C822.719 194.036 825.749 197.167 825.749 201.914H840.495C840.495 188.582 830.395 180.603 816.962 180.603C803.63 180.603 793.025 188.986 793.025 201.409C793.025 207.772 795.651 216.559 811.003 221.407L819.386 223.73C826.355 225.75 827.87 228.477 827.769 231.608C827.668 236.153 823.628 238.981 817.568 238.981C810.801 238.981 807.468 235.042 807.165 230.598H792.318ZM857.871 181.411V251H872.617V181.411H857.871ZM949.742 192.016C943.682 185.047 934.39 180.603 923.583 180.603C903.686 180.603 887.93 196.662 887.93 216.357C887.93 235.951 903.686 252.01 923.583 252.01C943.581 252.01 957.519 240.496 957.519 214.438H921.765V226.457H940.45C940.45 233.729 931.057 238.476 923.583 238.476C911.867 238.476 902.777 228.376 902.777 216.357C902.777 204.237 911.867 194.339 923.583 194.339C929.643 194.339 934.895 197.066 938.834 201.409L949.742 192.016ZM1023.56 181.411H1008.71V223.023L984.876 181.411H971.342V251H986.088V208.883L1010.33 251H1023.56V181.411Z" fill="#76FFD8" />
    </svg>

  );
};

export default SvgComponent;
