import React from "react";
import BotResponse from "./BotResponse";

const IntroSection = ({greeting}) => {
  return (
    <div id="introsection">

      <h1><BotResponse response={greeting} firstMessage={true}/></h1>
    </div>
  );
};

export default IntroSection;
