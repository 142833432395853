import React, { useEffect, useRef, useState, createRef } from "react";
import Avatar from "../components/Avatar";
import BotResponse from "../components/BotResponse";
import Error from "../components/Error";
import IntroSection from "../components/IntroSection";
import Loading from "../components/Loading";
import NavContent from "../components/NavContent";
import favicon from "../favicon.ico";

function generateRandomId() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

const ChatHome = ({ url }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setReponseFromAPI] = useState(false);
  const [id, setId] = useState("");

  const chatLogRefs = useRef([]);
  const answersBottomRef = useRef();

  const handleInput = (e) => {
    setInputPrompt(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== "") {
        // Set responseFromAPI to true before making the fetch request
        setReponseFromAPI(true);
        setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
        callAPI();

        // hide the keyboard in mobile devices
        e.target.blur();
        e.target.style.height = "46px";
      }

      async function callAPI() {
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Bypass-Tunnel-Reminder": "true", 
              Authorization:
                "Basic " +
                btoa(
                  localStorage.getItem("user") +
                    ":" +
                    localStorage.getItem("password")
                ),
            },
            body: JSON.stringify({ session_id: id, query: inputPrompt }),
          });
          const data = await response.json();
          setChatLog([
            ...chatLog,
            {
              chatPrompt: inputPrompt,
              botMessage: data['answer'],
            },
          ]);
          setErr(false);
        } catch (err) {
          setErr(err);
          console.log(err);
        }
        //  Set responseFromAPI back to false after the fetch request is complete
        setReponseFromAPI(false);
      }
    }

    setInputPrompt("");
  };

  useEffect(() => {
    chatLogRefs.current = Array(chatLog.length)
      .fill()
      .map((_, idx) => chatLogRefs.current[idx] || createRef());
    answersBottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatLog]);

  useEffect(() => {
    // check if ID exists in local storage
    const existingId = sessionStorage.getItem("id");

    if (existingId) {
      setId(existingId);
    } else {
      // generate a new ID
      const newId = generateRandomId();
      setId(newId);

      // save new ID to local storage
      localStorage.setItem("id", newId);
    }

    return () => {};
  }, []);

  return (
    <>
      <header>
        <div className="menu">
          <button onClick={() => setShowMenu(true)}>
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="#d9d9e3"
              strokeLinecap="round"
            >
              <path d="M21 18H3M21 12H3M21 6H3" />
            </svg>
          </button>
        </div>
        <h1>Textbooks.Live</h1>
      </header>

      <nav className={`navMenu ${showMenu && "open"}`}>
        <div className="navItems">
          <NavContent
            chatLog={chatLog}
            setChatLog={setChatLog}
            setShowMenu={setShowMenu}
          />
        </div>
        <div className="navCloseIcon">
          <svg
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            xmlSpace="preserve"
            stroke="#fff"
            width={42}
            height={42}
            onClick={() => setShowMenu(false)}
          >
            <path d="m53.691 50.609 13.467-13.467a2 2 0 1 0-2.828-2.828L50.863 47.781 37.398 34.314a2 2 0 1 0-2.828 2.828l13.465 13.467-14.293 14.293a2 2 0 1 0 2.828 2.828l14.293-14.293L65.156 67.73c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L53.691 50.609z" />
          </svg>
        </div>
      </nav>

      {/* <aside className="sideMenu">
        <NavContent
          chatLog={chatLog}
          setChatLog={setChatLog}
          setShowMenu={setShowMenu}
        />
      </aside> */}

      <section className="chatBox">
        {chatLog.length > 0 ? (
          chatLog.map((chat, idx) => (
            <div
              className="chatLog"
              key={idx}
              ref={chatLogRefs.current[idx]}
              id={`navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, "-")}`}
            >
              <div className="chatPromptMainContainer">
                <div className="chatPromptWrapper">
                  <Avatar bg="#5437DB" className="userSVG">
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth={1.9}
                      viewBox="0 0 24 24"
                      // strokeLinecap="round"
                      // strokeLinejoin="round"
                      className="h-6 w-6"
                      height={40}
                      width={40}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                  </Avatar>
                  <div id="chatPrompt">{chat.chatPrompt}</div>
                </div>
              </div>

              <div className="botMessageMainContainer">
                <div className="botMessageWrapper">
                  <Avatar bg="#FFFFFF" className="openaiSVG">
                    <img src={favicon} alt="avatar" width={41} height={41} />
                  </Avatar>
                  {chat.botMessage ? (
                    <div id="botMessage">
                      <BotResponse
                        response={chat.botMessage}
                        chatLogRef={chatLogRefs.current[idx]}
                        answersBottomRef={answersBottomRef}
                      />
                    </div>
                  ) : err ? (
                    <Error err={err} />
                  ) : (
                    <Loading />
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <IntroSection />
        )}
      </section>

      <form onSubmit={handleSubmit} className="inputPromptWrapper">
        <div>
          <textarea
            name="inputPrompt"
            id=""
            className="inputPrompttTextarea"
            type="text"
            rows="1"
            value={inputPrompt}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            autoFocus
          ></textarea>
          <button aria-label="form submit" type="submit">
            <svg
              fill="#ADACBF"
              width={15}
              height={20}
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
              stroke="#212023"
              strokeWidth={0}
            >
              <title>{"submit form"}</title>
              <path
                d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                stroke="none"
              />
            </svg>
          </button>
        </div>
      </form>
      <div ref={answersBottomRef}></div>
    </>
  );
};

export default ChatHome;
