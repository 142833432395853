import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));

if (localStorage.getItem('saved_password') !== null && localStorage.getItem('saved_password') !== undefined) {
  localStorage.setItem('password', localStorage.getItem('saved_password'));
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
  reportWebVitals();
} else {
  const password = prompt('Enter password:')
  
  localStorage.setItem('user', 'planorama')
  if (password) localStorage.setItem('password', password); else localStorage.removeItem('password');

  const response = fetch("https://dev-api.textbooks.live/validate-token", {
    method: "GET",
    headers: { "Bypass-Tunnel-Reminder": "true", "Content-Type": "application/json", 'Authorization': 'Basic ' + btoa(localStorage.getItem('user') + ':' + localStorage.getItem('password')) },
  }).then(data => {
    console.log(data)
    if (!data.ok) {
      root.render(
        <html>
          <head>
            <title>Error 401</title>
          </head>

          <body>
            <div>
              <h1>Error 401 - Unauthorized</h1>
            </div>
          </body>
        </html>
      )
    } else {
      localStorage.setItem('saved_password', password)
      root.render(
        <React.StrictMode>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </React.StrictMode>
      );
    }
    reportWebVitals();

  }).catch(e => {
    root.render(
      <html>
        <head>
          <title>Error 401</title>
        </head>

        <body>
          <div class="error-middle">
            <h1>Error 401 - Unauthorized</h1>
            <p>The 401 (Unauthorized) status code indicates that the request has not been applied because it lacks valid authentication credentials for the target resource. The server generating a 401 response MUST send a WWW-Authenticate header field (Section 4.1) containing at least one challenge applicable to the target resource.</p>
          </div>
        </body>
      </html>
    )
    reportWebVitals();
  })

}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

